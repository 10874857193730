import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from 'src/models/auth';
import { AuthenticationService } from 'src/services/auth.service';
import { AppStorage } from 'src/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    public authS: AuthenticationService,
    private appStorage: AppStorage,
    public router: Router,
    public route: ActivatedRoute
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.guard();
  }
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard();
  }
  guard() {
    const currentUser: User =  this.authS.currentUserValue
    if (currentUser?.access_token) return true;
    else {
      this.router.navigate(['/login'], {
        queryParams: { r: location.href.replace(location.origin, '') },
      });
      return false;
    }
  }
}
