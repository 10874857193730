import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import {
  I18NEXT_SERVICE,
  I18NextLoadResult,
  I18NextModule,
  defaultInterpolationFormat,
} from 'angular-i18next';
import { ITranslationService } from 'angular-i18next/lib/ITranslationService';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next
      .use(HttpApi)
      .use(LanguageDetector)
      .init({
        supportedLngs: ['en', 'ru'],
        fallbackLng: 'en',
        debug: true, // set debug?
        returnEmptyString: false,
        ns: ['translation', 'validation', 'error'],
        interpolation: {
          format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
        },
        //backend plugin options
        backend: {
          loadPath: 'locales/{{lng}}.{{ns}}.json',
        },
        // lang detection plugin options
        detection: {
          // order and from where user language should be detected
          order: ['cookie'],

          // keys or params to lookup language from
          lookupCookie: 'lang',

          // cache user language on
          caches: ['cookie'],

          // optional expire and domain for set cookie
          cookieMinutes: 10080, // 7 days
          // cookieDomain: I18NEXT_LANG_COOKIE_DOMAIN
        },
      });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
