import { Component, Renderer2 } from '@angular/core';
import { IAlert } from 'src/models/interface';
import { UtilsService } from 'src/services/utils.service';
import { InputService } from './components/input.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'naijavax';
  alert: IAlert = null
  constructor(private utils: UtilsService, private renderer: Renderer2, private inputSer: InputService){
    this.utils.alertSubject.subscribe((r)=>{
     this.alert = r
    })
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      event.stopPropagation()
      this.inputSer.setBodyClick(true)
    });
  }


  closeAlert(){
     this.utils.closeAlert()
  }
}
