import { createAction, props } from '@ngrx/store';

export const setOrganization = createAction(
  'Set Organization',
  props<{ data: object[] }>()
);

export const setOrganizations = createAction(
  'Set Organizations',
  props<{ data: object[] }>()
);
