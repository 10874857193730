import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, of } from 'rxjs';
import { AuthenticationService } from '../auth.service';
import { environment } from 'src/environments/environment';
// import { AuthenticationService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkProvider {
  constructor(private http: HttpClient, private injector: Injector) {}

  get httpHeader() {
    const auth = this.injector.get(AuthenticationService);

    return {
      'Accept-Language':
        auth.currentUserValue?.language || environment.language,
      'X-Tenant': 'hospital',
      'X-Version': `${environment.version}.0`,
    };
  }

  makePostRequest(url: string, data: HttpParams | any, extra?) {
    let urlString = `${url}`;
    let params = new HttpParams();
    //  const body = data
    let header = extra;
    if (!header) {
      header = { 'Content-Type': 'application/json' };
    }

    if (data instanceof HttpParams) {
      params = data;
    }
    return this.http
      .post(urlString, data, {
        headers: {
          ...header,
          ...this.httpHeader,
        },
        // params: data instanceof  HttpParams ? data : {}
      })
      .pipe(
        map((r) => {
          return { status: true, message: 'Success', data: r };
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  makeGetRequest(url: string, params?: any, extra?) {
    let header = extra;
    if (!header) {
      header = { 'Content-Type': 'application/json' };
    }
    return this.http
      .get(url, {
        headers: {
          ...header,
          ...this.httpHeader,
        },
        params: params,
      })
      .pipe(
        map((r) => {
          if (header['Content-Type'] === 'application/json')
            return { status: true, message: 'Success', data: r };
          else return r;
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  makedownloadRequest(url: string, params?: any, extra?) {
    return this.http
      .get(url, {
        headers: {
          ...this.httpHeader,
        },
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((r: HttpResponse<ArrayBuffer>) => {
          return r;
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  makePatchRequest(url: string, string, data?: any, extra?) {
    let header = extra;
    if (!header) {
      header = { 'Content-Type': 'application/json' };
    }
    return this.http
      .patch(url, data, {
        headers: {
          ...header,
          ...this.httpHeader,
        },
      })
      .pipe(
        map((r) => {
          return { status: true, message: 'Success', data: r };
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  makePutRequest(url: string, data?: any, extra?) {
    let header = extra;
    if (!header) {
      header = { 'Content-Type': 'application/json' };
    }
    return this.http
      .put(url, data, {
        headers: {
          ...header,
          ...this.httpHeader,
        },
      })
      .pipe(
        map((r) => {
          return { status: true, message: 'Success', data: r };
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  makeDeleteRequest(url: string, params?: any, extra?) {
    let header = extra;
    if (!header) {
      header = { 'Content-Type': 'application/json' };
    }
    return this.http
      .delete(url, {
        headers: {
          ...header,
          ...this.httpHeader,
        },
      })
      .pipe(
        map((r) => {
          return { status: true, message: 'Success', data: r };
        }),
        catchError((er) => {
          return this.handleError(er);
        })
      );
  }

  protected buildUrlParams(query): URLSearchParams {
    let params: URLSearchParams = new URLSearchParams();

    for (let key in query) {
      params.set(key.toString(), query[key]);
    }

    return params;
  }

  protected handleError = (er) => {
    console.log(er);
    if (er.status === 500)
      throw {
        status: false,
        message:
          'Error occured at server, if the problem persisit please contact support.',
      };
    if (er.status === 401) {
      const auth = this.injector.get(AuthenticationService);
      auth.refreshToken();
      throw { status: false, message: 'Unathorized Access' };
    } else {
      if (er?.error?.errors) {
        if (!Array.isArray(er?.error?.errors)) {
          let validations = [];
          let errors = [];
          console.log(er?.error?.errors);
          for (const key of Object.keys(er?.error?.errors)) {
            validations.push(key);
            errors.push(er?.error?.errors[key as any]);
          }

          throw {
            status: false,
            message: errors.join('\n'),
            validators: validations,
          };
        } else
          throw {
            status: false,
            message: er?.error?.errors.join('\n'),
            validators: er?.error?.errors,
          };
      } else {
        throw {
          status: false,
          message:
            er?.error?.error_description ||
            er?.error?.error ||
            er?.error?.detail ||
            er.message,
        };
      }
    }
  };
}
