import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.auth.currentUserValue?.access_token;
    // cloned headers, updated with the authorization.
    if (authToken) {
        // Clone the request and attach the token
     const authReq = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${authToken}`
          }
        });
  
        return next.handle(authReq);
      }
      
    //   req.headers.set('X-Tenant', 'hospital')
    //    req.headers.set('Accept-Language', 'en-GB')
  
      return next.handle(req);
    // send cloned request with header to the next handler.
  }
}