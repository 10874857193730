<router-outlet></router-outlet>

@if (alert) {
  <div class="alertMain">
  <div role="alert" class="rounded-xl border border-gray-100 bg-white p-4 alert">
    <div class="flex items-start gap-4">
      <span class="{{alert.type === 'error' ? 'text-red' : 'text-green-500'}}">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
  
      <div class="flex-1">
        <strong class="block font-medium text-gray-900 capitalize"> {{alert?.type}} </strong>
  
        <p class="mt-1 text-sm text-gray-700"> {{alert?.message}} </p>
      </div>
  
      <button (click)="closeAlert()" class="text-gray-500 transition hover:text-gray-600">
        <span class="sr-only">Dismiss popup</span>
  
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-6 w-6"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</div>
}
