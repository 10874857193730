import { createReducer, on } from '@ngrx/store';
import * as OrganizationActions from '../actions/organization.action';

export interface OrganizationState {
  organization: object;
  organizations: object[];
}

export const initialState: OrganizationState = {
  organization: {},
  organizations: [],
};

export const OrganizationReducer = createReducer(
  initialState,
  on(OrganizationActions.setOrganization, (state, data) => {
    return { ...state, organization: data.data };
  }),
  on(OrganizationActions.setOrganizations, (state, data) => {
    return { ...state, organizations: data.data };
  })
);
