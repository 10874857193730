import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InputService {
    bodyClick: BehaviorSubject<boolean> = new BehaviorSubject(false)
    closeSelectByName: BehaviorSubject<string> = new BehaviorSubject(null)
    closeSelectByCustomId: BehaviorSubject<string> = new BehaviorSubject(null)

    constructor(){}


    setBodyClick(value){
      this.bodyClick.next(value)
    }

    setCloseByName(value){
        this.closeSelectByName.next(value)
      }


      setcloseSelectByCustomId(value){
        this.closeSelectByCustomId.next(value)
      }


}