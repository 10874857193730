import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkProvider } from './network/network';
import { ICountries, INV } from 'src/models/interface';
import { AppStorage } from './storage.service';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
    currentOrgSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    
    currentWkspacesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    currentWkspaceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private networkProvider: NetworkProvider,
    private appStorage: AppStorage
  ) {}

  public get currentOrgValue(): any {
    return this.currentOrgSubject.value || this.appStorage.getItem('vaxOrg');
  }

  public get currentWkspacesValue(): any {
    if(this.currentWkspacesSubject.value.length){
      return this.currentWkspacesSubject.value
    }
     return this.appStorage.getItem('vaxWkspaces')||[];
  }

  public get currentWkspaceValue(): any {
    if(this.currentWkspaceSubject.value)return this.currentWkspaceSubject.value
    return  this.appStorage.getItem('vaxWkspace');
  }

  changeWk(wk){
    this.appStorage.saveItem('vaxWkspace', wk)
    this.currentWkspaceSubject.next(wk)
  }

  fetchOrganizations(){
    return  this.networkProvider.makeGetRequest(environment.baseUrl + `/organization/v${environment.version}/organizations`)
  }

  fetchOrgWorkspaces(id=this.currentOrgValue.id){
       return this.networkProvider.makeGetRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${id}/workspaces`)
       .pipe(map((r:any)=>{
          if(Array.isArray(r.data) && r.data?.length){
            if(this.currentWkspaceValue) {
              const curr = r.data.find((r)=>r.id === this.currentWkspaceValue.id)
              this.appStorage.saveItem('vaxWkspace', curr)
              this.currentWkspaceSubject.next(curr)
            }

              else{
                 this.currentWkspaceSubject.next(r.data[0])
                 this.appStorage.saveItem('vaxWkspace', r.data[0])
                }
           }
            this.currentWkspacesSubject.next(r.data)
            this.appStorage.saveItem('vaxWkspaces',r.data)
        return r.data
       }))
  }

  createOrganization(payload){
    return  this.networkProvider.makePostRequest(environment.baseUrl + `/organization/v${environment.version}/organizations`,payload)
  }


  updateOrganization(id, payload){
    return  this.networkProvider.makePutRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${id}`,payload)
  }

  createWorkspaces(payload, id = this.currentOrgValue.id,){
    return  this.networkProvider.makePostRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${id}/workspaces`,payload)
    .pipe(map((r)=>{
      this.fetchOrgWorkspaces(id)
      return r
    }))
  }


  updateWorkspace(orgId, wkspId ,payload){
    return  this.networkProvider.makePutRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${orgId}/workspaces/${wkspId}`,payload)
  }

  fetchOrgWorkspace(orgId, wkspId ,payload){
    return  this.networkProvider.makeGetRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${orgId}/workspaces/${wkspId}`,payload)
  }

  fetchOrganization(id){
   // return new Observable((sub)=>{

  return  this.networkProvider.makeGetRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${id}`)
  .pipe(map((r:any)=>{
    this.currentWkspacesSubject.next(r.data)
    this.appStorage.saveItem('vaxOrg',r.data)
return r.data
   }))
      // .subscribe({
      //   next: (r)=>{
      // //  this.appStorage.saveItem('vaxOrg',r.data)
      // //    this.currentOrgSubject.next(r.data)
      //     sub.next(r.data)
      //   },
      //   error: (er)=>{
      //       sub.error(er)
      //   }
      // })

    //})
  }

  approveOrganization(id){
    return  this.networkProvider.makePutRequest(environment.baseUrl + `/organization/v${environment.version}/organizations/${id}/approve`)
  }

  

}
