import {  Injectable, inject } from '@angular/core';
import { AppStorage } from './storage.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NetworkProvider } from './network/network';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { User } from 'src/models/auth';
import { OnboardingService } from './onboarding.service';
 
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    null
  );
  public currentUserOb: Observable<User> =
    this.currentUserSubject.asObservable();
  baseUrl = environment.authBaseUrl;

  // auth: Auth;

  constructor(
    private appStorage: AppStorage,
    private orgService: OnboardingService,
    private netProvider: NetworkProvider
  ) {
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value || this.appStorage.getItem('vaxAuth');
  }

  get isAuthenticated() {
    // return !!this.appStorage.getItem('vaxAuth');
    return !!this.currentUserValue?.access_token;
  }

  login(params: any) {
    let url = `${this.baseUrl}/connect/token`;
    const body = new HttpParams()
     .set('scope', environment.scope)
     .set('username', params.username)
     .set('password', params.password)
     .set('grant_type', environment.grant_type)
     .set('client_id', environment.client_id)
    
  const contentType = 'application/x-www-form-urlencoded'
    return this.netProvider.makePostRequest(url, body , contentType)
           .pipe(map((r:any)=>{
            let userdata: User = Object.assign({}, r.data);
            userdata.language = environment.language;

            this.appStorage.saveItem(
              'vaxAuth',
              userdata
            );

            this.currentUserSubject.next(userdata);
             this.setTimer()
            this.fetchUserProfile()
            return { status: true, message: 'Success' };
           }),
            catchError((er)=>{
              throw {status: false, message:  er.error || er.message}
            }))
  }

  setTimer(){
    const timer =  setTimeout(()=>{
      this.refreshToken()
      clearTimeout(timer)
    },33000000)
  }

  refreshToken(){
    let url = `${this.baseUrl}/connect/token`;
    const body = new HttpParams()
     .set('refresh_token', this.currentUserValue.refresh_token)
     .set('grant_type', 'refresh_token')
     .set('client_id', environment.client_id)
    
   const contentType = 'application/x-www-form-urlencoded'
   this.netProvider.makePostRequest(url, body , contentType)
           .subscribe({
            next:(r:any)=>{
              console.log(r)
            let userdata: User = Object.assign({},{ ...this.currentUserValue,...r.data});
            userdata.language = this.currentUserValue.language;

            this.appStorage.saveItem(
              'vaxAuth',
              userdata
            );

            this.currentUserSubject.next(userdata);
            window.location.reload()
            },
            error: (er)=>{
             this.logout()
            }
        }
  )
  }

  signUp(params: any) {
    const body = new HttpParams()
    .set('firstName', params.firstName)
    .set('lastName', params.lastName)
    .set('password', params.password)
    .set('email', params.email)
    .set('role', params.role)

    return this.netProvider.makePostRequest(environment.authBaseUrl+`/v${environment.version}/register`, params)
           .pipe(map((r)=>{
            return { status: true, message: 'Success' };
           }),
            catchError((er)=>{
              throw {status: false, message:  er.error || er.message}
            }))
  }

  fetchUserProfile(){
    this.netProvider.makeGetRequest(environment.authBaseUrl + '/connect/userinfo')
    .subscribe({
      next:(r:any)=>{
        let userdata: User = Object.assign({}, {...r.data, ...this.currentUserValue, language: r.data?.language||this.currentUserValue?.language});

        this.appStorage.saveItem(
          'vaxAuth',
          userdata
        );

        this.currentUserSubject.next(userdata);
      },
      error:(er)=>{

      }
    })
  }

  activateAccount(email){
    this.netProvider.makeGetRequest(environment.authBaseUrl + `/v${environment.version}/verify-email?email=${email}&code=123456`)
    .subscribe()
  }


  logout() {
    this.appStorage.removeItem('vaxAuth');
    this.appStorage.removeItem('vaxWkspace');
    this.appStorage.removeItem('vaxWkspaces');
    this.appStorage.removeItem('vaxOrg');

    this.appStorage.removeItem('bloodGroups');
    this.appStorage.removeItem('genotypes');
    this.appStorage.removeItem('vaccines');
    this.appStorage.removeItem('notificationTypes');

    this.appStorage.removeItem('countries');
    this.appStorage.removeItem('languages');
    this.appStorage.removeItem('timezones');
    
    window.location.reload();
  }
}
