import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IAlert } from 'src/models/interface';

@Injectable({ providedIn: 'root' })
export class UtilsService {

  alertSubject: BehaviorSubject<IAlert> = new BehaviorSubject(null)
  constructor(

  ) {}

  upperCaseValidator = (control: AbstractControl) => {
    if (/[A-Z]/.test(control.value)) return null;
    else {
      return { noUpperCase: true };
    }
  };

  specialCharValidator = (control: AbstractControl) => {
    if (/[ -/:-@[-`{-~]/.test(control.value)) return null;
    else {
      return { noSpecialChar: true };
    }
  };

  containNumber = (control: AbstractControl) => {
    if (/\d/.test(control.value)) return null;
    else {
      return { noNumber: true };
    }
  };

  setAlert(alert: IAlert){
    this.alertSubject.next(alert)
    const timeout= setTimeout(()=>{
      if(this.alertSubject.value)this.alertSubject.next(null)
      clearTimeout(timeout)
    },3000)
  }

  closeAlert(){
    this.alertSubject.next(null)
  }

  numberToText(num) {
    switch (num) {
      case 1:
        return "First";
      case 2:
        return "Second";
      case 3:
        return "Third";
      case 4:
        return "Fourth";
      case 5:
        return "Fifth";
      default:
        return num + "th";
    }
  }
}
