import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from 'src/models/auth';
import { AuthenticationService } from 'src/services/auth.service';
import { OnboardingService } from 'src/services/onboarding.service';
import { AppStorage } from 'src/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class OrgnizationGuard {
  constructor(
    public onbSer: OnboardingService,
    private appStorage: AppStorage,
    public router: Router,
    public route: ActivatedRoute
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.guard();
  }
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard();
  }
  guard() {
    const currentOrg: User =  this.onbSer.currentOrgValue
    if (currentOrg) return true;
    else {
      this.router.navigate(['/organizations']);
      return false;
    }
  }
}
