import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from 'src/services/translation.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../services/network/http-interceptor';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from 'src/store/app.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    I18NextModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
  ],
  providers: [
    I18N_PROVIDERS,
    // provideOAuthClient(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
