import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/guard/auth.guard';
import { OrgnizationGuard } from 'src/guard/org.gaurd';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/signup.module').then((r) => r.SignUpModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((r) => r.LoginModule),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./auth/reset/reset.module').then((r) => r.ResetModule),
  },
  {
    path: 'onboarding',
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./auth/onboarding/onboarding.module').then(
        (r) => r.OnboardingModule
      ),
  },
  {
    path: 'workspace-onboarding',
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./auth/w-onboarding/w-onboarding.module').then(
        (r) => r.WorkspaceOnboardingModule
      ),
  },
  {
    path: 'organizations',
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./auth/organizations/organizations.module').then(
        (r) => r.OrganizationsModule
      ),
  },
  {
    path: 'app',
    canActivateChild: [OrgnizationGuard],
    loadChildren: () => import('./base/base.module').then((m) => m.BaseModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
